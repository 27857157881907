import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import styled from 'styled-components'
import { renderRichText as renderContentfulRichText } from 'gatsby-source-contentful/rich-text'
import { ContentfulLink } from './link'
import EmbedVideo from 'ui/src/EmbedVideo'
import { Table, TableRow, TableCell, TableHeaderCell } from 'ui/src/Table'

const renderMark = {
  [MARKS.BOLD]: text => <strong>{text}</strong>,
  [MARKS.ITALIC]: text => <em>{text}</em>,
  [MARKS.UNDERLINE]: text => <u>{text}</u>,
}

const renderText = text => {
  return text.split('\n').reduce((children, textSegment, index) => {
    const testingText = textSegment
      .split('_')
      .flatMap((t, i) => [
        i > 0,
        process.env.GATSBY_BUDDY_PIPELINE === 'japan' ? <StyledSpan>{t}</StyledSpan> : t,
      ])
    return [...children, index > 0 && <br key={index} />, testingText]
  }, [])
}

const renderNode = () => ({
  [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
  [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
  [BLOCKS.TABLE_CELL]: (node, children) => <TableCell>{children}</TableCell>,
  [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <TableHeaderCell>{children}</TableHeaderCell>,

  [INLINES.HYPERLINK]: (node, children) => {
    const { data } = node

    const target =
      // internal link
      data.uri.startsWith('/') ||
      // mailto
      data.uri.includes('mailto:') ||
      // anchor
      data.uri.startsWith('#')
        ? '_self'
        : '_blank'

    return (
      <StyledAnchor href={data.uri} target={target} rel="noopener noreferrer">
        {children}
      </StyledAnchor>
    )
  },

  [INLINES.EMBEDDED_ENTRY]: ({ data, nodeType }) => {
    const type = data.target?.__typename || nodeType
    const entry = data.target

    if (type === 'ContentfulMedia' && entry.video) {
      return <EmbedVideo url={entry.video} />
    }

    if (type === 'ContentfulMedia' && entry.mediaAsset) {
      const image = (
        <img
          alt={entry.mediaAsset?.description || 'image'}
          src={entry.mediaAsset?.file.url}
          className={entry.alignment ? `inline-${entry.alignment}` : ''}
        />
      )

      if (entry.link) {
        return (
          <ContentfulLink
            linkData={entry.link}
            image={image}
            className={entry.alignment ? `inline-${entry.alignment}` : ''}
          >
            {image || entry.link.title}
          </ContentfulLink>
        )
      }

      return image
    }

    if (type === 'ContentfulAnchor') {
      return <a href="#" id={entry.anchorName} aria-label={`${entry.anchorName} anchor`} />
    }

    return null
  },

  [BLOCKS.EMBEDDED_ENTRY]: ({ data, nodeType }) => {
    const type = data.target?.__typename || nodeType
    const entry = data.target

    if (type === 'ContentfulLink') {
      return (
        <ContentfulLink
          linkData={entry}
          className={entry.alignment ? `inline-${entry.alignment}` : ''}
        >
          {entry.title}
        </ContentfulLink>
      )
    }

    return null
  },
})

const renderRichText = (body, allPages) =>
  renderContentfulRichText(body, {
    renderMark,
    renderText,
    renderNode: renderNode(allPages),
  })

const StyledAnchor = styled.a`
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  overflow-wrap: break-word;
`

const StyledSpan = styled.span`
  display: inline-block;
`

export { renderRichText }
